import React from 'react';
import _ from 'lodash';
import { Button } from 'antd';
import { action, observable } from 'mobx';
import { request, SearchListModal } from '../../utils';
import type { BaseData, IEgGridModel, IMainSubStructureModel, MainSubStructureModel, NormalProgrammeParams } from '../../utils';
import RefundModel from './modal/refundModel';
import RefundRecordModel from './modal/refundRecordModel';
import { API } from './api';
import dayjs from 'dayjs';

interface Statistics {
  totalAmount: number;
  totalCount: string;
  totalRefundAmount: number;
}

export default class Model {
  constructor() {
    // this.getPriceCategory();
    this.pageStore.grid.onQuery();
  }
  /* TODO 后端说没有退款 */
  /*
  public refundStore = new RefundModel({ parent: this });

  public refundRecordStore = new RefundRecordModel({ parent: this }); */

  @observable
  public filterSet: Partial<NormalProgrammeParams> = {
    filterItems: [
      {
        type: 'input',
        label: '社群名称',
        field: 'groupName',
      },
      {
        type: 'input',
        label: '用户昵称',
        field: 'nickName',
      },
      {
        type: 'input',
        label: '手机号',
        field: 'mobile',
      },
      {
        type: 'select',
        label: '订单状态',
        field: 'orderStatus',
        data: [
          {
            label: '未支付',
            value: '0',
          },
          {
            label: '已支付',
            value: '1',
          },
          {
            label: '已退款',
            value: '2',
          },
        ],
      },
      {
        type: 'dateRange',
        label: '支付时间',
        field: 'time',
        format: 'YYYY-MM-DD',
      },
      {
        type: 'input',
        label: '用户ID',
        field: 'userId',
      },
    ],
  };

  /* 统计 */
  @observable
  public statistics: Statistics = {
    totalAmount: 0,
    totalCount: '0',
    totalRefundAmount: 0,
  };

  /* 统计赋值 */
  @action
  public setStatistics = (data: Statistics) => {
    this.statistics = data;
    this.pageStore.grid.collectData = [
      {
        name: '总量',
        value: this.statistics.totalCount || 0,
        color: 'blue',
      },
      {
        name: '总金额',
        value: this.statistics.totalAmount || 0,
        color: 'blue',
      },
      /* TODO 后端说没有退款 */
      /* {
        name: '已退款',
        value: this.statistics.totalRefundAmount || 0,
        color: 'blue',
      }, */
    ];
  };

  @observable
  public grid: IMainSubStructureModel = {
    collectData: [
      {
        name: '总量',
        value: this.statistics.totalCount,
        color: 'blue',
      },
      {
        name: '总金额',
        value: this.statistics.totalAmount,
        color: 'blue',
      },
      /* TODO 后端说没有退款 */
      /* {
        name: '已退款',
        value: this.statistics.totalRefundAmount,
        color: 'blue',
      }, */
    ],
    grid: {
      columns: [
        /* 后端说现在没有退款 */
        /* {
          key: 'operate',
          name: '操作',
          width: 100,
          formatter: ({ row }) => (
            <div>
              {row.orderStatus === 1 ? (
                <Button
                  onClick={() => {
                    console.log('refund');
                    this.refundStore.onShow(row);
                  }}
                  type="link"
                >
                  退款
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    console.log('refundDetail');
                    this.refundRecordStore.onShow(row);
                  }}
                  type="link"
                >
                  退款原因
                </Button>
              )}
            </div>
          ),
        }, */
        {
          key: 'orderNo',
          name: '编号',
          width: 240,
        },
        {
          key: 'groupName',
          name: '社群名称',
        },
        {
          key: 'nickName',
          name: '用户',
        },
        {
          key: 'mobile',
          name: '手机号',
          width: 220,
        },
        {
          key: 'priceTemplateDetailTitle',
          name: '会员',
          // formatter: ({ row }) => this.getPriceCategoryName(row.priceTemplateDetailPriceCategory),
        },
        {
          key: 'priceTemplateDetailPrice',
          name: '价格',
          width: 150,
        },

        {
          key: 'orderStatus',
          name: '状态',
          width: 150,
          formatter: ({ row }) => {
            if (row.orderStatus === 0) {
              return '未支付';
            }
            if (row.orderStatus === 1) {
              return '已支付';
            }
            if (row.orderStatus === 2) {
              return '已退款';
            }
            return '';
          },
        },
        {
          key: 'payTime',
          name: '支付时间',
          width: 150,
          formatter: ({ row }) => {
            if (row.payTime) {
              return dayjs(row.payTime).format('YYYY-MM-DD HH:mm:ss');
            }
            return '';
          },
        },
        {
          key: 'validEndTime',
          name: '会员到期时间',
          width: 150,
          formatter: ({ row }) => dayjs(row.validEndTime).format('YYYY-MM-DD HH:mm:ss'),
        },
      ].map((item) => ({
        resizable: true,
        sortable: false,
        ...item,
      })),
      rows: [],
      primaryKeyField: 'id',
      sortByLocal: false,
      showCheckBox: false,
      showEmpty: true,
      showGridOrderNo: false,
    },
    api: {
      onQuery: (params: IEgGridModel['queryParam']) => {
        const { filterParams, pageNum, pageSize } = params;
        const timeParams: { payStartTime?: string; payEndTime?: string } = {};
        if (filterParams.time) {
          const times = filterParams.time.split(',');
          if (times.length === 2) {
            timeParams.payStartTime = new Date(times[0]).valueOf().toString();
            timeParams.payEndTime = new Date(times[1]).valueOf().toString();
          }
          delete filterParams.time;
        }

        const postParams = {
          ...filterParams,
          ...timeParams,
          pageNum,
          pageSize,
        };
        return request<BaseData<any>>({
          url: API.fetchOrderList,
          method: 'POST',
          data: postParams,
        });
      },
      callbackAfterQuery: (_this: MainSubStructureModel) => {
        const { filterParams, pageNum, pageSize } = _this.history;
        const postParams = {
          ...filterParams,
          pageNum,
          pageSize,
        };
        request<{ code: number; msg?: string; data: Statistics }>({
          url: API.fetchOrderStatistics,
          method: 'POST',
          data: postParams,
        })
          .then((res) => {
            if (res.code === 200) {
              this.setStatistics(res.data);
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {});
      },
    },
  };

  public pageStore = new SearchListModal({
    programme: this.filterSet,
    grid: this.grid,
  });

  /* 查询字典相关 */

  // @observable
  // public unitOptions = [];

  // @action
  // public getPriceCategory = async () => {
  //   const req = await request<{ data: any[] }>({
  //     method: 'GET',
  //     url: '/quan/sys/dict/type',
  //     params: { dictType: 'price_category' },
  //   });
  //   this.unitOptions = req.data;
  // };

  // public getPriceCategoryName = (id: string): string => {
  //   let name = '';
  //   this.unitOptions.forEach((item) => {
  //     if (item.dictValue === id) {
  //       name = item.dictName;
  //     }
  //   });
  //   return name;
  // };
}
